import React, { useState } from "react";
import { Typography } from "@mui/material";
import { BoltingData } from "../../apis/types";
import ClassificationMenu from "../../components/ClassificationMenu/ClassificationMenu";
import { useAppDispatch } from "../../store/hooks";
import { setFeedbackClassification } from "../../store/labellingSlice";

interface ClassificationFeedbackComponentInput {
  row: BoltingData;
  updateSelectedRow: (row: BoltingData) => void;
}

export const ClassificationFeedbackComponent: React.FC<
  ClassificationFeedbackComponentInput
> = props => {
  const [selectedClassification, setSelectedClassification] = useState<string>(
    props.row.classification || "-"
  );

  const dispatch = useAppDispatch();

  const handleDropdownChange = (classification: string) => {
    setSelectedClassification(classification);
    dispatch(setFeedbackClassification(classification));
  };

  return (
    <div>
      <Typography variant="h6">Classification</Typography>
      <ClassificationMenu
        selectedClassification={selectedClassification}
        onClassificationChange={handleDropdownChange}
      />
    </div>
  );
};

export default ClassificationFeedbackComponent;
