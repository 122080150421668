// import { DesignItTabs, DesignItTab } from "@design-it/react-library";
import "./analysisPage.scss";
import { useState, useRef, useEffect } from "react";
import { PrepareAnalysis } from "./PrepareAnalysis";
import { ExecuteAnalysis } from "./ExecuteAnalysis";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useCSVContext } from "../../providers/CSVProvider";
import { UploadDropContainerProps } from "../../components/uploadDropContainer/uploadDropContainer";
import {
  uploadFile,
  fetchModelConfigs,
  verifyFile,
} from "../upload/uploadHelpers";
import { S3 } from "aws-sdk";
import { ModelConfigs, TabProps } from "./types";
import { useStepperContext } from "../../providers/StepperProvider";
import {
  MLFlowStepperLabelsAnalysisTab,
  MLFlowStepperLabelsExecuteTab,
} from "../../constants/stepper.constants";
import { Box, CircularProgress } from "@mui/material";
import { PageContent } from "../../components/PageContent/PageContent";
import { TabsContent } from "../../components/Tabs/Tabs";
import { Stepper } from "../dashboard/Stepper";
import { useAnalysisContext } from "../../providers/AnalysisProvider";

export const AnalysisPage = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadHandler, setUploadHandler] = useState<S3.ManagedUpload | null>(
    null
  );
  const { setProgress, setFileName, setLines } = useCSVContext();
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [modelConfigs, setModelConfigs] = useState<ModelConfigs>({});
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsActiveTab = searchParams.get("activeTab");
  const [hasValidModelConfig, setHasValidModelConfig] = useState<
    boolean | null
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { setFile, file } = useAnalysisContext();
  const { isExecuteTabAnalysis, setIsExecuteTabAnalysis } = useStepperContext();

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const data = await fetchModelConfigs();
      setModelConfigs(data);

      const validConfig = Object.keys(data).length > 0;
      setHasValidModelConfig(validConfig);
      setLines([]);
      setIsLoading(false);
    })();

    if (!searchParamsActiveTab) {
      searchParams.set("activeTab", "prepare");
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    if (file) {
      setFileName(file.name);
    }
  }, [file]);

  useEffect(() => {
    if (hasValidModelConfig === null) {
      return;
    }
    const params = new URLSearchParams();

    if (!searchParamsActiveTab) {
      params.set("activeTab", "prepare");
      setIsExecuteTabAnalysis(false);
      navigate(`?${params.toString()}`);
    } else if (searchParamsActiveTab === "execute" && hasValidModelConfig) {
      setIsExecuteTabAnalysis(true);
    } else {
      if (searchParamsActiveTab === "execute" && !hasValidModelConfig) {
        params.set("activeTab", "prepare");
        setIsExecuteTabAnalysis(false);
        navigate(`?${params.toString()}`);
      }

      if (
        searchParamsActiveTab !== "prepare" &&
        searchParamsActiveTab !== "execute"
      ) {
        params.set("activeTab", "prepare");
        setIsExecuteTabAnalysis(false);
        navigate(`?${params.toString()}`);
      }
    }
  }, [hasValidModelConfig, searchParamsActiveTab]);

  const onTabClick = (tabName: string) => {
    setIsExecuteTabAnalysis(tabName === "execute");

    searchParams.set("activeTab", tabName);
    setSearchParams(searchParams);
  };

  const handleUpload = uploadFile(
    file,
    setFileName,
    setUploadHandler,
    setShowUploadModal,
    setProgress,
  );

  const uploadDropContainerProps: UploadDropContainerProps = {
    file,
    fileInputRef,
    setFile,
    verifyFile,
    uploadHandler,
    setShowUploadModal,
    showUploadModal,
  };

  const tabProps: TabProps = {
    handleUpload: handleUpload,
    showUploadModal: showUploadModal,
    file: file,
    modelConfigs: modelConfigs,
    uploadContainerProps: uploadDropContainerProps,
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <PageContent
      title={
        isExecuteTabAnalysis
          ? "Execute your analysis"
          : "Configure your analysis"
      }
      showButtonBar={true}
      nextButton={{ hide: true }}
      backButton={{ handler: () => navigate("/start") }}
    >
      <Box>
        <Stepper
          steps={
            isExecuteTabAnalysis
              ? MLFlowStepperLabelsExecuteTab
              : MLFlowStepperLabelsAnalysisTab
          }
          currentStep={1}
          shouldShowStepper={true}
        />
      </Box>

      <TabsContent
        defaultValue={searchParamsActiveTab ?? ""}
        tabs={[
          {
            label: "Prepare Analysis",
            value: "prepare",
            content: <PrepareAnalysis {...tabProps} />,
            dataTestId: "prepare-analysis-tab-button",
          },
          {
            label: "Execute Analysis",
            value: "execute",
            content: <ExecuteAnalysis {...tabProps} />,
            disabled: Boolean(!hasValidModelConfig),
            dataTestId: "execute-analysis-tab-button",
          },
        ]}
        onChange={onTabClick}
      />
    </PageContent>
  );
};
