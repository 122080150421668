import { DesignItStepper, DesignItStepperStep } from "@design-it/react-library";
import "./stepper.scss";
import { Box } from "@mui/material";

export type StepperProps = {
  shouldShowStepper: boolean;
  currentStep: number;
  steps: string[];
};

export function Stepper(props: StepperProps) {
  const getState = (stepperStep: number) => {
    if (stepperStep === props.currentStep) {
      return "current";
    } else if (stepperStep < props.currentStep) {
      return "success";
    } else {
      return "open";
    }
  };

  return (
    <>
      {props.shouldShowStepper && (
        <Box className="flex justify-center pt-8 pb-8">
          <DesignItStepper className="px-[22px]" data-testid="stepper">
            {props.steps.map((step, index) => (
              <DesignItStepperStep
                key={index}
                state={getState(index + 1)}
                label={step}
                {...(getState(index + 1) === "success" && { icon: "checked" })}
              />
            ))}
          </DesignItStepper>
        </Box>
      )}
    </>
  );
}
