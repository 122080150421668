import { Autocomplete, Box, Button, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { CLIPBOARD_TEXT, TOOLTIP_TEXT } from "./analysis.constants";
import { useStepperContext } from "../../providers/StepperProvider";
import { Navigate } from "react-router-dom";
import { UploadDropContainer } from "../../components/uploadDropContainer/uploadDropContainer";
import { TabProps } from "./types";
import { useCSVContext } from "../../providers/CSVProvider";
import { useModelConfigs } from "./UseModelConfigs";
import { Option } from "./AnalysisHelpers";
import { CopyToClipboard } from "../../components/CopyToClipboard/CopyToClipboard";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { primaryColors } from "../../theme/DarkTheme";
import { useAnalysisContext } from "../../providers/AnalysisProvider";

const PageContainerBoxStyle = {
  width: "100%",
  display: "flex"
};

const LeftTabBoxStyle = {
  display: "flex",
  flexDirection: "column",
  flexBasis: "50%",
};

const AutoCompleteBoxStyle = {
  display: "flex",
  flexDirection: "column",
  marginTop: '1px',
  paddingRight: "25px"
};

const RightTabBoxStyle = {
  display: "flex",
  flexDirection: "column",
  flexBasis: "50%"
};

const FormatTypographyStyle = {
  display: "flex",
  paddingLeft: "32px",
  marginTop: '1px',
  gap: 0.6
};

const ValidationButtonStyle = {
  position: 'absolute',
  bottom: '32px',
  right: '32px',
  width: '240px'
};

export const getPids = (options: Option[], selectedToolId: string) => {
  return selectedToolId
    ? Array.from(new Set(options
      .filter(option => option.tool_id === selectedToolId)
      .map(option => option.process_id)))
    : [];
};

export const getAnalysisNames = (options: Option[], selectedPid: string) => {
  return selectedPid
    ? Array.from(new Set(options
      .filter(option => option.process_id === selectedPid)
      .map(option => option.name)))
    : [];
};

export const getTorques = (options: Option[], selectedAnalysisName: string) => {
  return selectedAnalysisName
    ? Array.from(new Set(options
      .filter(o => o.name === selectedAnalysisName)
      .map(o => o.target_torque)))
    : [];
};

export const getAngles = (
  options: Option[],
  selectedAnalysisName: string,
  selectedTorque: string
) => {
  return selectedTorque
    ? Array.from(new Set(options
      .filter(o => o.target_angle)
      .filter(o => o.name === selectedAnalysisName)
      .map(o => o.target_angle)))
    : [];
};

export const ExecuteAnalysis: React.FC<TabProps> = ({
  uploadContainerProps,
  ...props
}) => {
  const { setStep, setShowStepper } = useStepperContext();
  const { progress, setProgress, fileName } = useCSVContext();

  useEffect(() => {
    setStep(1);
    setShowStepper(true);
    localStorage.removeItem("fileLines");
    localStorage.removeItem("lines");
  }, []);

  const { loading, options, toolIds } = useModelConfigs(props.modelConfigs);

  const {
    file,
    execute,
    setExecuteObj
  } = useAnalysisContext();

  const { toolId, processId, configName, targetAngle, targetTorque } = execute;

  const pids = useMemo(
    () => getPids(options, toolId || ""),
    [toolId, options]
  );

  const analysisNames = useMemo(
    () => getAnalysisNames(options, processId || ""),
    [processId, options]
  );

  const torques = useMemo(
    () => getTorques(options, configName || ""),
    [options, configName]
  );

  const angles = useMemo(
    () => getAngles(options, configName || "", targetTorque || ""),
    [options, targetTorque]
  );

  const onValueChange = (key: keyof typeof execute, value: string | null) => {
    let innerExecuteObject: Record<keyof typeof execute, string | null> = {
      toolId: null,
      processId: null,
      configName: null,
      targetTorque: null,
      targetAngle: null,
    };

    if (key === 'toolId') {
      innerExecuteObject = { ...innerExecuteObject, toolId: value };
    }

    if (key === 'processId') {
      innerExecuteObject = {
        ...innerExecuteObject,
        toolId,
        processId: value
      };
    }

    if (key === 'configName') {
      innerExecuteObject = {
        ...innerExecuteObject,
        toolId,
        processId,
        configName: value
      };
    }

    if (key === 'targetTorque') {
      innerExecuteObject = {
        ...innerExecuteObject,
        toolId,
        processId,
        configName,
        targetTorque: value
      };
    }

    if (key === 'targetAngle') {
      innerExecuteObject = {
        toolId,
        processId,
        configName,
        targetTorque,
        targetAngle: value
      };
    }

    setExecuteObj(innerExecuteObject);
  };

  const buttonDisabled = !toolId ||
    !configName ||
    !processId ||
    !targetTorque ||
    !file;

  const onHandleUploadClick = () => {
    if (!buttonDisabled) {
      props.handleUpload();
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (progress === 100) {
    setProgress(0);
    return (
      <Navigate
        to={`/data-check?fileName=${fileName}&configName=${configName}`}
      />
    );
  }

  return (
    <Box sx={PageContainerBoxStyle}>
      <Box sx={LeftTabBoxStyle}>
        <Typography>1. Choose an analysis job</Typography>

        <Box sx={AutoCompleteBoxStyle}>
          <Autocomplete
            options={toolIds}
            value={toolId}
            onChange={(_, value) => onValueChange('toolId', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={'Tool ID'}
                sx={{ "& .MuiOutlinedInput-root": { padding: "0" } }}
              />
            )}
          />

          <Autocomplete
            options={pids}
            value={processId}
            disabled={!toolId}
            onChange={(_, value) => onValueChange('processId', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={'Process ID'}
                sx={{ "& .MuiOutlinedInput-root": { padding: "0" } }}
              />
            )}
          />

          <Autocomplete
            options={analysisNames}
            disabled={!processId || !toolId}
            value={configName}
            onChange={(_, value) => onValueChange('configName', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  "data-testid": "config-name-input"
                }}
                label={'Name your analysis job'}
                sx={{ "& .MuiOutlinedInput-root": { padding: "0" } }}
              />
            )}
          />

          <Autocomplete
            options={torques}
            disabled={
              !configName ||
              !processId ||
              !toolId
            }
            value={targetTorque}
            onChange={(_, value) => onValueChange('targetTorque', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  "data-testid": "target-torque-input"
                }}
                label={'Target Torque'}
                sx={{ "& .MuiOutlinedInput-root": { padding: "0" } }}
              />
            )}
          />

          <Autocomplete
            options={angles}
            disabled={
              !targetTorque ||
              !configName ||
              !processId ||
              !toolId
            }
            value={targetAngle}
            onChange={(_, value) => onValueChange('targetAngle', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  "data-testid": "target-angle-input"
                }}
                label={'Target Angle'}
                sx={{ "& .MuiOutlinedInput-root": { padding: "0" } }}
              />
            )}
          />
          <Typography className="pl-4">Optional</Typography>
        </Box>
      </Box>

      <Box sx={RightTabBoxStyle}>
        <Typography sx={FormatTypographyStyle}>
          2. Upload an analysis file. Use this
          <CopyToClipboard
            className="inline"
            text={CLIPBOARD_TEXT}
            label={"format"}
          />

          <Tooltip sx={{marginTop: "1px"}} title={<Box sx={{ fontSize: '12px' }}>{TOOLTIP_TEXT}</Box>}>
            <InfoOutlinedIcon sx={{ marginTop: "1px", color: primaryColors.main }}  fontSize={"small"} />
          </Tooltip>
          .
        </Typography>

        <UploadDropContainer {...uploadContainerProps} />
      </Box>

      <Button
        variant="contained"
        data-testid="submit-button"
        sx={ValidationButtonStyle}
        onClick={() => onHandleUploadClick()}
        disabled={buttonDisabled}
      >
        Validate
      </Button>
    </Box>
  );
};
