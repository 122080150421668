import DetailedGraphView from "../../components/graphView/detailedGraphViewComponent";
import { FeedbackComponent } from "../dashboard/feedbackComponent";
import { useResultsContext } from "../../providers/resultsProvider";
import { BoltingData } from "../../apis/types";
import { RowViewTable } from "./rowViewTable";
import { Box } from "@mui/material";
import "./BoltingDataDetail.scss";

interface RowViewComponentProps {
  modelExists: boolean;
  selectedRow?: BoltingData | null;
}

export function RowViewComponent({
  modelExists,
  selectedRow
}: Readonly<RowViewComponentProps>) {
  const { currentRow, setCurrentRow } = useResultsContext();
  const rowToUse = selectedRow ?? currentRow;

  if (!rowToUse) {
    console.log("in row to use");
    return <div>No data available...</div>;
  }

  return (
    <div className="h-full">
      <div className="flex min-w-full h-full gap-[10px]">
        <div className="flex flex-grow flex-col gap-[10px]">
          <Box
            className="h-[16%]"
            sx={{ background: theme => theme.palette.info.dark }}
          >
            <RowViewTable
              data={[rowToUse]}
              isLoading={false}
              hideArrow={true}
              modelExists={modelExists}
              className="h-full py-0 mt-2 pl-16 pr-0 min-h-fit"
            />
          </Box>
          <Box
            className="h-[84%] p-8"
            sx={{ background: theme => theme.palette.info.dark }}
          >
            {rowToUse && <DetailedGraphView row={rowToUse} />}
          </Box>
        </div>
        <Box
          sx={{ background: theme => theme.palette.info.dark, gap: "10px" }}
          className="newFeedbackComponentContainer"
        >
          <FeedbackComponent
            row={rowToUse}
            updateSelectedRow={setCurrentRow}
          />
        </Box>
      </div>
    </div>
  );
}
