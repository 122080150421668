import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { ApiError } from "./httpResponse";
import { apiHelper } from "./apiHelper";
import { getCurrentUser } from "@aws-amplify/auth";
import moment from "moment";

export type UpdateBoltingDataFeedbackApiBody = {
  configName: string;
};

export const updateBoltingDataFeedback = async (
  data: UpdateBoltingDataFeedbackApiBody
): Promise<unknown> => {
  const user_id = (await getCurrentUser())["userId"];
  const timestamp = moment().format("YYYY-MM-DD_HH:mm:ss.SSS");

  const url =
    process.env.REACT_APP_REST_API_ROOT_URL + "/model/optimize-threshold";

  const response = await apiHelper.post(url, {
    userId: user_id,
    timestamp: timestamp,
    ...data,
  });

  return response;
};

export const useUpdateBoltingDataFeedback = (
  options?: UseMutationOptions<
    unknown,
    ApiError,
    UpdateBoltingDataFeedbackApiBody
  >
) =>
  useMutation({
    mutationFn: (data: UpdateBoltingDataFeedbackApiBody) =>
      updateBoltingDataFeedback(data),
    ...options,
  });
