import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { BoltingDataRequestBody } from "../types/boltingData";
import { getIdToken } from "../utils/credentialsHelper";
import { FeedbackValues } from "../components/table/CustomHeader";

export interface LabellingState {
  status: 'idle' | 'loading' | 'failed';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[] | null;
  currentProgress: number;
  feedback: FeedbackValues | null;
  feedbackClassification: string | null;
}

const initialState: LabellingState = {
  status: 'idle',
  data: null,
  currentProgress: 0,
  feedback: null,
  feedbackClassification: null,
};

export const fetchLabellingData = createAsyncThunk('labelling/fetchLabellingData', 
  async ({ page_size, page_number, additional_filters } : BoltingDataRequestBody, thunkAPI) => {
    const currentUser = (thunkAPI.getState() as RootState).user.currentUser?.userId;

    if (!currentUser) {
      return [];
    }
    const data = {
      user_id: currentUser,
      page_size: page_size,
      page_number: page_number,
      use_new_implementation: true,
      additional_filters: additional_filters,
    };

    const idToken = await getIdToken();
    const response = await fetch(process.env.REACT_APP_REST_API_ROOT_URL + '/get-bolting-operations', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      }, 
      body: JSON.stringify(data),
    });
    const result = await response.json();
    return result.body;
  });

export const labellingSlice = createSlice({
  name: 'labelling',
  initialState,
  reducers: {
    setFoundCurrentProgress: (state, action) => {
      const currentIndex = state.data ? state.data.findIndex((item) => item.id === action.payload) : -1;
      state.currentProgress = currentIndex;
    },
    setFeedback: (state, action) => {
      state.feedback = action.payload;
    },
    setFeedbackClassification: (state, action) => {
      state.feedbackClassification = action.payload;
    },
    resetFeedback: (state) => {
      state.feedback = null;
      state.feedbackClassification = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLabellingData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLabellingData.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload;
      });
  }
});

export default labellingSlice.reducer;

export const { setFoundCurrentProgress, setFeedback, setFeedbackClassification, resetFeedback } = labellingSlice.actions;