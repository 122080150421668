import React, { useEffect } from "react";
import { BoltingData } from "../../apis/types";
import { FeedbackValues } from "../../components/table/CustomHeader";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import "./index.scss";
import ClassificationFeedbackComponent from "./classificationFeedbackComponent";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setFeedback, setFeedbackClassification } from "../../store/labellingSlice";

interface feedbackComponentInput {
  row: BoltingData;
  updateSelectedRow: (row: BoltingData) => void;
}

export function FeedbackComponent(props: feedbackComponentInput) {
  const license = useAppSelector((state) => state.user.license);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setFeedback(props.row?.feedback || null));
    dispatch(setFeedbackClassification(props.row?.classification || null));
  }, [dispatch, props.row?.feedback]);

  const currentFeedback = useAppSelector((state) => state.labelling.feedback);

  const setNewFeedback = (feedback: string) => {
    dispatch(setFeedback(feedback));
    if(feedback === FeedbackValues.OK) {
      dispatch(setFeedbackClassification(null));
    }
  };

  return (
    <Box className="feedback--innerContainer">
      <Typography>Your Feedback</Typography>
      <Typography>
        To train the AI model, we need feedback from you, whether you can see an
        anomaly on this curve or not.
      </Typography>
      <ToggleButtonGroup
        sx={{ width: "100%" }}
        value={currentFeedback}
        exclusive
        onChange={(_, v) => setNewFeedback(v)}
      >
        <ToggleButton
          size="small"
          sx={{ textTransform: "none", width: "50%" }}
          value={FeedbackValues.NOK}
        >
          Anomaly
        </ToggleButton>
        <ToggleButton
          size="small"
          sx={{ textTransform: "none", width: "50%" }}
          value={FeedbackValues.OK}
        >
          No Anomaly
        </ToggleButton>
      </ToggleButtonGroup>
      {license === "premium" && currentFeedback === FeedbackValues.NOK ? (
        <ClassificationFeedbackComponent
          row={props.row}
          updateSelectedRow={props.updateSelectedRow}
        />
      ) : null}
    </Box>
  );
}
