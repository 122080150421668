import { Box, FormControl, Input, InputLabel, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { PageContent } from "../../components/PageContent/PageContent";
import { Navigate, useNavigate } from "react-router-dom";
import { Stepper } from "../dashboard/Stepper";
import { NonMLFlowStepperLabel } from "../../constants/stepper.constants";
import { useRef, useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { errorColors, primaryColors, tertiaryColors } from "../../theme/DarkTheme";
import { zodResolver } from "@hookform/resolvers/zod";
import { S3 } from "aws-sdk";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { nonMLValidationSchema } from "./NonMLFlowValidations";
import { CopyToClipboard } from "../../components/CopyToClipboard/CopyToClipboard";
import { CLIPBOARD_TEXT, TOOLTIP_TEXT } from "../AnalysisPage/analysis.constants";
import { UploadDropContainer, UploadDropContainerProps } from "../../components/uploadDropContainer/uploadDropContainer";
import { uploadFile, verifyFile } from "../upload/uploadHelpers";
import { useCSVContext } from "../../providers/CSVProvider";
import { useAnalysisContext } from "../../providers/AnalysisProvider";

export const NonMLFlowAnalysis = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadHandler, setUploadHandler] = useState<S3.ManagedUpload | null>(
    null
  );
  const { progress, setProgress, setFileName, fileName } = useCSVContext();
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const uploadDropContainerProps: UploadDropContainerProps = {
    file,
    fileInputRef,
    setFile,
    verifyFile,
    uploadHandler,
    setShowUploadModal,
    showUploadModal,
  };

  const handleUpload = uploadFile(
    file,
    setFileName,
    setUploadHandler,
    setShowUploadModal,
    setProgress,
  );

  const {
    prepare: { toolId, processId, configName, targetTorque, targetAngle },
    setPrepareObj
  } = useAnalysisContext();

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const {
    control,
    formState,
    watch,
    handleSubmit
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(
      nonMLValidationSchema()
    ),
    defaultValues: {
      toolId,
      processId,
      configName,
      targetTorque,
      targetAngle
    }
  });

  const values = watch();

  useEffect(() => {
    setPrepareObj({
      toolId: values.toolId ?? '',
      processId: values.processId ?? '',
      targetAngle: values.targetAngle ?? '',
      targetTorque: values.targetTorque ?? '',
      configName: values.configName ?? ''
    });
  }, [values]);

  const disableButton =
    !values.configName ||
    !values.processId ||
    !values.targetTorque ||
    !file;

  const onSubmit = () => {
    if (!disableButton)
      handleUpload();
  };

  if (progress === 100) {
    setProgress(0);

    return (
      <Navigate
        to={`/non-ml-flow-data-check?fileName=${fileName}&configName=${values.configName}`}
      />
    );
  }

  return (
    <PageContent
      showButtonBar={true}
      title="Profound results in a short amount of time"
      backButton={{ handler: () => navigate("/start") }}
      nextButton={{ disabled: disableButton, handler: handleSubmit(onSubmit), text: "Let's go!" }}
    >
      <Box>
        <Stepper
          steps={NonMLFlowStepperLabel}
          currentStep={1}
          shouldShowStepper={true}
        />
      </Box>
      <Box>
        <Tabs
          variant="fullWidth"
          value={selectedTab}
          onChange={handleChange}
          centered
        >
          <Tab label={"Execute Analysis"} data-testid="tab" />
        </Tabs>
        <Box sx={{ width: "100%", display: "flex" }}>
          <Box sx={{ display: "flex", flexDirection: "column", flexBasis: "50%", paddingRight: "25px" }}>
            <Typography sx={{ marginTop: "25px" }}>
              1. Define you analysis job.
            </Typography>

            <FormControl>
              <InputLabel
                sx={{
                  color: formState.errors.processId ? errorColors.main : tertiaryColors.main,
                  "&.Mui-focused": {
                    color: formState.errors.processId ? errorColors.main : primaryColors.main,
                  },
                }}
              >
                Process ID
              </InputLabel>
              <Controller
                control={control}
                name="processId"
                render={({ field }) => (
                  <Input
                    {...field}
                    inputProps={{ "data-testid": "processId" }}
                    error={Boolean(formState.errors.processId)}
                  />
                )}
              />

              {Boolean(formState.errors.processId) &&
                <Box data-testid="processId-error">
                  <Typography
                    color={errorColors.main}
                    className="text-xs"
                  >
                    {formState.errors.processId?.message}
                  </Typography>
                </Box>
              }
            </FormControl>

            <FormControl>
              <InputLabel
                sx={{
                  color: formState.errors.configName ? errorColors.main : tertiaryColors.main,
                  "&.Mui-focused": {
                    color: formState.errors.configName ? errorColors.main : primaryColors.main,
                  },
                }}>
                Name you analysis job
              </InputLabel>
              <Controller
                control={control}
                name="configName"
                render={({ field }) => (
                  <Input
                    {...field}
                    inputProps={{ "data-testid": "configName" }}
                    error={(Boolean(formState.errors.configName))}
                  />
                )}
              />

              {Boolean(formState.errors.configName) &&
                <Box data-testid="configName-error">
                  <Typography
                    sx={{ color: errorColors.main }}
                  >
                    {formState.errors.configName?.message}
                  </Typography>
                </Box>
              }
            </FormControl>

            <FormControl>
              <InputLabel
                sx={{
                  color: formState.errors.toolId ? errorColors.main : tertiaryColors.main,
                  "&.Mui-focused": {
                    color: formState.errors.toolId ? errorColors.main : primaryColors.main,
                  },
                }}              >
                Tool ID
              </InputLabel>
              <Controller
                control={control}
                name="toolId"
                render={({ field }) => (
                  <Input
                    {...field}
                    inputProps={{ "data-testid": "toolId" }}
                    error={(Boolean(formState.errors.toolId))}
                  />
                )}
              />
              {Boolean(formState.errors.toolId) &&
                <Box data-testid="toolId-error">
                  <Typography
                    sx={{ color: errorColors.main }}
                  >
                    {formState.errors.toolId?.message}
                  </Typography>
                </Box>
              }
            </FormControl>

            <FormControl>
              <InputLabel
                sx={{
                  color: formState.errors.targetTorque ? errorColors.main : tertiaryColors.main,
                  "&.Mui-focused": {
                    color: formState.errors.targetTorque ? errorColors.main : primaryColors.main,
                  },
                }}
              >
                Target torque
              </InputLabel>
              <Controller
                control={control}
                name="targetTorque"
                render={({ field }) => (
                  <Input
                    {...field}
                    inputProps={{ "data-testid": "targetTorque" }}
                    error={(Boolean(formState.errors.targetTorque))}
                  />
                )}
              />

              {Boolean(formState.errors.targetTorque) &&
                <Box data-testid="targetTorque-error">
                  <Typography
                    sx={{ color: errorColors.main }}
                  >
                    {formState.errors.toolId?.message}
                  </Typography>
                </Box>
              }
            </FormControl>

            <FormControl>
              <InputLabel>
                Target angle
              </InputLabel>
              <Controller
                control={control}
                name="targetAngle"
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Enter Target Angle"
                    inputProps={{ "data-testid": "targetAngle" }}
                    disabled={
                      !values.configName ||
                  !values.processId ||
                  !values.toolId ||
                  !values.targetTorque
                    }
                  />
                )}/>
             
              <Typography className="pt-2 pl-4">Optional</Typography>
            </FormControl>

          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", flexBasis: "50%" }}>
            <Typography sx={{ display: "flex", paddingLeft: "32px", gap: 0.3, marginTop: "25px" }}>
              2. Upload an analysis file. Use this

              <CopyToClipboard
                className="inline"
                text={CLIPBOARD_TEXT}
                label="format"
              />

              <Tooltip sx={{fontSize: "60px"}} title={<Box sx={{ fontSize: '12px' }}>{TOOLTIP_TEXT}</Box>}>
                <InfoOutlinedIcon sx={{ marginTop: "1px", color: primaryColors.main }} fontSize={"small"} />
              </Tooltip>
              .
            </Typography>

            <UploadDropContainer {...uploadDropContainerProps} />
          </Box>
        </Box>

      </Box>
    </PageContent>
  );
};
