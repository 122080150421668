import React from "react";
import ReactDOM from "react-dom/client";

import { ResultsProvider } from "./providers/resultsProvider";
import { ReactQueryProvider } from "./providers/ReactQueryProvider";
import { StepperProvider } from "./providers/StepperProvider";
import { CSVProvider } from "./providers/CSVProvider";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme/DarkTheme";
import App from "./App";

import "./theme/designit.scss";
import "./index.scss";
import { MenuProvider } from "./providers/MenuProvider";
import { AnalysisProvider } from "./providers/AnalysisProvider";
import { Provider } from "react-redux";
import { store } from "./store/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function computeBaseName() {
  const pathSegments = window.location.pathname.split("/");
  if (pathSegments.length > 1 && pathSegments[1].startsWith("pr-")) {
    return `/${pathSegments[1]}`;
  }
  return "";
}

const basename = computeBaseName();

root.render(
  <React.StrictMode>
    <ReactQueryProvider>
      <ResultsProvider>
        <CSVProvider>
          <Provider store={store}>
            <Router basename={basename}>
              <StepperProvider>
                <AnalysisProvider>
                  <ThemeProvider theme={theme}>
                    <MenuProvider>
                      <div className="ics-blue-dark">
                        <App />
                      </div>
                    </MenuProvider>
                  </ThemeProvider>
                </AnalysisProvider>
              </StepperProvider>
            </Router>
          </Provider>
        </CSVProvider>
      </ResultsProvider>
    </ReactQueryProvider>
  </React.StrictMode>
);
