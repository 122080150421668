import { useParams, useNavigate } from "react-router-dom";
import { useGetBoltingDataById } from "../../apis/useGetBoltingData";
import { RowViewComponent } from "./rowViewComponent";
import { useStepperContext } from "../../providers/StepperProvider";
import { useCallback, useEffect, useMemo } from "react";
import { DesignItProgress } from "@design-it/react-library";
import { PageContent } from "../../components/PageContent/PageContent";
import { Box } from "@mui/material";
import { fetchLabellingData, resetFeedback, setFoundCurrentProgress } from "../../store/labellingSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectCurrentUser } from "../../store/userSlice";
import { apiHelper } from "../../apis/apiHelper";
import { endpoints } from "../../apis/endpoints";

type BoltingDataDetailPageProps = {
  modelExists?: boolean;
};

export const BoltingDataDetailPage = ({
  modelExists = true,
}: BoltingDataDetailPageProps) => {
  const { id } = useParams();
  const { data, isLoading } = useGetBoltingDataById(id);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);

  const { setShowStepper } = useStepperContext();

  useEffect(() => {
    setShowStepper(false);
  }, [setShowStepper]);

  const labellingData = useAppSelector((state) => state.labelling.data);

  const fetchInitialData = useCallback(async () => {
    await dispatch(fetchLabellingData({ page_size: 1000, page_number: 1 }));
    dispatch(setFoundCurrentProgress(data?.id));
  }, [data?.id, dispatch]);

  useEffect(() => {
    if(currentUser !== null && labellingData === null) {
      fetchInitialData();
    }
    if(currentUser !== null && labellingData && labellingData.length > 0) {
      dispatch(setFoundCurrentProgress(data?.id));
    }
  }, [currentUser, data?.id, dispatch, fetchInitialData, labellingData]);

  const feedback = useAppSelector((state) => state.labelling.feedback);
  const feedbackClassification = useAppSelector((state) => state.labelling.feedbackClassification);

  const sendFeedback = useCallback(async () => {
    if(data) {
      const response = await apiHelper.patch(
        endpoints.UPDATE_BOLTING_DATA_FEEDBACK,
        {
          PartitionKey: data["productId#processId"],
          SortKey: data.createdDate,
          Feedback: feedback,
          Classification: feedbackClassification,
        }
      );
    
    
      if (!response["Attributes"]) {
        throw Error("Setting feedback failed");
      }
    }
  }, [data, feedback, feedbackClassification]);


  const currentProgress = useAppSelector((state) => state.labelling.currentProgress);

  const prevLabellingId = useMemo(() => {
    return labellingData?.[currentProgress - 1]?.id || 'no_data';
  }, [labellingData, currentProgress]);

  const nextLabellingId = useMemo(() => {
    return labellingData?.[currentProgress + 1]?.id || 'no_data';
  }, [labellingData, currentProgress]);



  const sendFeedbackAndNavigate = useCallback(async (newId: string) => {
    await sendFeedback();
    dispatch(resetFeedback());
    if(newId === "-1") {
      navigate(-1);
    } else {
      navigate(`/labelling/${newId}`, { replace: true });
    }
  }, [dispatch, navigate, sendFeedback]);

  return (
    <PageContent
      showButtonBar={true}
      backButton={{ handler: () => sendFeedbackAndNavigate("-1") }}
      prevButton={{ handler: () => sendFeedbackAndNavigate(prevLabellingId), disabled: prevLabellingId === 'no_data' }}
      nextButton={{ handler: () => sendFeedbackAndNavigate(nextLabellingId), disabled: nextLabellingId === 'no_data' }}
      currentProgress={`${currentProgress + 1}/${labellingData ? labellingData.length : 0}`}
    >
      {isLoading ? (
        <Box
          className="w-full h-full flex justify-center items-center flex-col"
          data-testid="loading"
        >
          <DesignItProgress indeterminate />
        </Box>
      ) : (
        <Box className="h-full" data-testid="content">
          <RowViewComponent
            modelExists={Boolean(modelExists)}
            selectedRow={data}
          />
        </Box>
      )}
    </PageContent>
  );
};
