import React, { useState } from "react";
import LineChart from "./lineChart";
import { LineChartConfig } from "./lineChart";
import "./style.scss";

import { Tab, Tabs } from "@mui/material";
import { BoltingData } from "../../apis/types";

const combinedGraph = (
  rowData: BoltingData,
  graphType: string
): LineChartConfig => {
  const angleValues = rowData.angleInDeg?.split(";").map(Number);
  const torqueValues = rowData.torqueInNm?.split(";").map(Number);

  const timeValues = rowData.timeInSec?.split(";").map(Number);
  const datasets = [];
  if(graphType === "torqueToTime") {
    datasets.push({
      label: "bolting tool",
      data: timeValues.map((time: number, i: number) => ({
        x: time,
        y: torqueValues[i],
      })),
      borderColor: "#FFCC00",
      backgroundColor: "#FFCC00",
      borderWidth: 2,
      xAxisID: "x",
      yAxisID: "y",
    });

    if (rowData.reconstructedTorque) {
      const reconstructedValues = rowData.reconstructedTorque
        ?.split(";")
        .map(Number);
      datasets.push({
        label: "bolt_it prediction",
        data: timeValues.map((time: number, i: number) => ({
          x: time,
          y: reconstructedValues[i],
        })),
        borderColor: "#B076F8",
        backgroundColor: "#B076F8",
        borderWidth: 2,
      });
    }
  }

  if (graphType === 'angleToTime') {
    datasets.push({
      label: "Angle",
      data: timeValues.map((time: number, i: number) => ({
        x: time,
        y: angleValues[i],
      })),
      borderColor: "#8AD6B8",
      backgroundColor: "#8AD6B8",
      borderWidth: 2,
      xAxisID: "x",
      yAxisID: "y1",
    });
  }

  if (graphType === 'torqueToAngle') {
    datasets.push({
      label: "bolting tool",
      data: timeValues.map((time: number, i: number) => ({
        x: angleValues[i],
        y: torqueValues[i],
      })),
      borderColor: "#FFCC00",
      backgroundColor: "#FFCC00",
      borderWidth: 2,
      xAxisID: "x1",
    });

    const reconstructedValues = rowData.reconstructedTorque
      ?.split(";")
      .map(Number);
    datasets.push({
      label: "bolt_it prediction",
      data: timeValues.map((time: number, i: number) => ({
        x: angleValues[i],
        y: reconstructedValues[i],
      })),
      borderColor: "#B076F8",
      backgroundColor: "#B076F8",
      borderWidth: 2,
    });
  }

  return {
    labels: timeValues,
    datasets: datasets,
    xTitle: ["Time[s]", "Angle[°]"],
    yTitle: ["Torque[Nm]", "Angle[°]"],
    title: "",
    graphType: graphType
  };
};
interface CombinedGraphProps {
  row: BoltingData;
}

export default function CombinedGraph(props: CombinedGraphProps) {
  const [graphType, setGraphType] = useState<string>("torqueToTime");

  const config = combinedGraph(props.row, graphType);

  const handleToggle = (type: string) => {
    setGraphType(type);
  };


  return (
    <div className="h-full ">
      <div className="h-[10%] flex items-center justify-start">
        <Tabs value={graphType}>
          <Tab
            sx={{ width: "100px", backgroundColor: "#262640" }}
            onClick={() => handleToggle("torqueToAngle")}
            label="Torque/Angle"
            value="torqueToAngle"
          />
          <Tab
            sx={{ width: "100px", backgroundColor: "#262640" }}
            onClick={() => handleToggle("torqueToTime")}
            label="Torque/Time"
            value="torqueToTime"
          />
          <Tab
            sx={{ width: "100px", backgroundColor: "#262640" }}
            onClick={() => handleToggle("angleToTime")}
            label="Angle/Time"
            value="angleToTime"
          />
        </Tabs>
      </div>
      <div className="max-h-full h-[90%]">
        <LineChart config={config} />
      </div>
    </div>
  );
}
