/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { Box, Typography, Select, MenuItem, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getAllBoltingData } from "../../apis/useGetBoltingData"; // Assuming this is your API
import { getCurrentUser } from "@aws-amplify/auth";
import { AnomalyResultsTagComponent } from "../../components/AnomalyTag/AnomalyTag";
import { DesignItProgress } from "@design-it/react-library";
import { AgGridReact, CustomCellRendererProps } from "@ag-grid-community/react";
import "./resultsTable.scss";
import { getResultTableColDefs, ResultsTableProps } from "../../components/table/CustomHeader";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { GridApi, ModuleRegistry } from "@ag-grid-community/core";
import { getBoltingOperationDataSize } from "../../utils/uploadLimitHelpers";

ModuleRegistry.registerModules([ClientSideRowModelModule]);

export const ResultsTable = ({
  onArrowClick,
  isLoading,
  className,
  hideArrow = false,
  rowsPerPage = 10,
  startPage = 1,
}: ResultsTableProps) => {
  const [currentPage, setCurrentPage] = useState(startPage);
  const [totalRows, setTotalRows] = useState(0);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [rowData, setRowData] = useState<any[]>([]);
  const pageChangeInProgress = useRef(false);
  const [loading, setLoading] = useState<boolean>(true);

  // Fetch the total row count for the grid
  const fetchTotalData = async () => {
    try {
      const { userId } = await getCurrentUser();
      const totalData = await getBoltingOperationDataSize(userId, "yes");
      setTotalRows(totalData);
    } catch (error) {
      console.error("Error fetching total data:", error);
    }
  };

  useEffect(() => {
    fetchTotalData();
  }, []);

  const iconStyle = useMemo(() => {
    return {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "22px",
      cursor: "pointer",
    };
  }, []);

  const anomalyCellRenderer = useCallback((props: CustomCellRendererProps) => {
    if (props.value !== undefined) {
      return <AnomalyResultsTagComponent value={props.value} />;
    }
  }, []);

  const arrowCellRenderer = useCallback(
    (props: CustomCellRendererProps) => (
      <ArrowForwardIcon
        style={{ cursor: "pointer" }}
        onClick={() => onArrowClick?.(props.data)}
      />
    ),
    [onArrowClick, iconStyle]
  );

  const idCellRenderer = useCallback((props: CustomCellRendererProps) => {
    return props.value ? (
      `${props.data.processId}`
    ) : (
      <DesignItProgress indeterminate style={{ "--md-circular-progress-size": "30px" }} />
    );
  }, []);

  const colDefs = getResultTableColDefs(
    idCellRenderer,
    anomalyCellRenderer,
    arrowCellRenderer,
    hideArrow
  );

  // Function to fetch data for the current page
  const fetchPageData = async (pageNumber: number) => {
    if (!gridApi) {
      console.error("Grid API is not ready yet.");
      return;
    }
    const { userId } = await getCurrentUser();
    const cacheKey = `boltingData_${userId}_${pageNumber}`;
    const cachedData = localStorage.getItem(cacheKey);

    if (cachedData) {
      if (cachedData !== "[]") {
        const data = JSON.parse(cachedData);
        setRowData(data);
        fetchTotalData();
        setLoading(false);
        return;
      }
    }

    const data = await getAllBoltingData(
      userId,
      { anomaly_detected: "yes" },
      rowsPerPage,
      pageNumber
    );

    const storageSize = JSON.stringify(localStorage).length;
    const dataSize = JSON.stringify(data).length;
    const remainingSpace = 5242880 - storageSize;

    if (dataSize < remainingSpace) {
      localStorage.setItem(cacheKey, JSON.stringify(data));
    } else {
      console.warn("LocalStorage is full or nearly full. Cannot cache data.");
    }

    setRowData(data);
    setCurrentPage(pageNumber);
    setLoading(false);
  };

  const onPaginationChanged = (event: any) => {
    if (!gridApi || pageChangeInProgress.current) return;
    const newPage = event.api.paginationGetCurrentPage() + 1;
    if (newPage !== currentPage) {
      pageChangeInProgress.current = true;
      gridApi.paginationGoToPage(newPage - 1); // -1 because grid uses 0-indexed pages
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    if (gridApi &&  currentPage && !pageChangeInProgress.current) {
      fetchPageData(currentPage);
    }
  }, [gridApi, currentPage]);

  const handlePageChange = (page: number) => {
    if (page !== currentPage) {
      setCurrentPage(page);
      fetchPageData(page);
    }
  };

  const onGridReady = useCallback((params: any) => {
    setGridApi(params.api);
  }, []);
  const renderContent = () => {
    return (
      <AgGridReact
        loading={loading || isLoading}
        columnDefs={colDefs}
        rowData={rowData}
        rowBuffer={10} // Ensures smooth loading of rows
        rowClass="custom-row"
        domLayout="autoHeight"
        headerHeight={48}
        rowHeight={48}
        paginationPageSize={rowsPerPage}
        cacheBlockSize={rowsPerPage}
        maxConcurrentDatasourceRequests={1}
        maxBlocksInCache={200}
        onGridReady={onGridReady}
        pagination={true}
        onPaginationChanged={onPaginationChanged}
        rowModelType="clientSide"
        suppressPaginationPanel={true}
        overlayLoadingTemplate={`<span class="ag-overlay-loading-center">Loading...</span>`}
        overlayNoRowsTemplate={
          !loading && rowData.length === 0
            ? `<div class="custom-no-rows">No Rows To Show</div>`
            : ""
        }
      />
    );
  };
  return (
    <div className={`result-layout h-full ${className}`}>
      <div className="ag-theme-material" style={{ height: "400px" }}>
        {renderContent()} {}
      </div>
      {!loading && rowData.length > 0 && ( // Show pagination controls only if data is loaded
        <Box className="pagination-container">
          <Typography>
            {`Items Per Page`}
          </Typography>

          {/* Page Dropdown */}
          <Select
            data-testid="page-dropdown"
            value={currentPage}
            onChange={(e) => handlePageChange(Number(e.target.value))}
          >
            {Array.from(
              { length: Math.ceil(totalRows / rowsPerPage) },
              (_, index) => (
                <MenuItem key={index + 1} value={index + 1}>
                  {index + 1}
                </MenuItem>
              )
            )}
          </Select>

          <Typography>
            {`Page ${currentPage} of ${Math.ceil(totalRows / rowsPerPage)}`}
          </Typography>

          {/* Back Arrow */}
          <IconButton
            data-testid="back-arrow"
            onClick={() => {
              if (currentPage > 1) {
                const newPage = currentPage - 1;
                handlePageChange(newPage);
              }
            }}
            disabled={currentPage === 1}
          >
            <ArrowBackIosNewIcon fontSize="small" />
          </IconButton>

          {/* Forward Arrow */}
          <IconButton
            data-testid="forward-arrow"
            onClick={() => {
              if (currentPage < Math.ceil(totalRows / rowsPerPage)) {
                const newPage = currentPage + 1;
                handlePageChange(newPage);
              }
            }}
            disabled={currentPage === Math.ceil(totalRows / rowsPerPage)}
          >
            <ArrowForwardIosIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </div>
  );
};
