import { AuthUser, getCurrentUser } from "@aws-amplify/auth";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { getIdToken } from "../utils/credentialsHelper";

export interface UserState {
  status: 'idle' | 'loading' | 'failed';
  currentUser: AuthUser | null;
  license: 'NaN' | 'basic' | 'premium' | 'enterprise';
}

const initialState: UserState = {
  status: 'idle',
  currentUser: null,
  license: 'NaN',
};

export const fetchCurrentUser = createAsyncThunk('user/fetchUser', async () => {
  const response = await getCurrentUser();
  return response;
});

export const fetchLicense = createAsyncThunk('user/fetchLicense', async (_, thunkAPI) => {
  const currentUser = (thunkAPI.getState() as RootState).user.currentUser?.userId;
  const idToken = await getIdToken();
  const url = process.env.REACT_APP_REST_API_ROOT_URL +
    "/configurations/get-model-configs";
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${idToken}`,
    },
    body: JSON.stringify({
      user_id: currentUser,
      operation: "check_license",
    }),
  });
  const result = await response.json();
  return result.license;
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.status = 'idle';
        state.currentUser = action.payload;
      })
      .addCase(fetchLicense.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLicense.fulfilled, (state, action) => {
        state.status = 'idle';
        state.license = action.payload;
      });
  }
});

export default userSlice.reducer;

export const selectCurrentUser = (state: { user: UserState }) => state.user.currentUser;