import * as z from "zod";

const TOOL_ID_ERROR = 'Please enter a valid ToolId (letters and numbers only + underscore).';
const PROCESS_ID_ERROR = 'Please enter a valid ProcessId (letters and numbers only + underscore).';
const CONFIG_NAME_ERROR = 'Please enter a valid Configuration Name (letters and numbers only + underscore). Maximum 30 characters';
const TARGET_TORQUE_ERROR = 'Please enter a valid Target Torque (letters and numbers only + underscore).';

export const nonMLValidationSchema = () => z.object({
  toolId: z
    .string({
      required_error: "ToolId is requierd",
      invalid_type_error: "Incorect"
    })
    .regex(/[A-Za-z0-9\s_]+/,
      { message: TOOL_ID_ERROR }
    ),
  processId: z
    .string({
      required_error: "ProcessId is required",
      invalid_type_error: "Incorrect"
    })
    .regex(
      /[A-Za-z0-9\s_]+/,
      { message: PROCESS_ID_ERROR }
    ),
  configName: z
    .string({
      required_error: "Configuration Name is required",
      invalid_type_error: "Incorrect"
    })
    .min(3)
    .regex(
      /[A-Za-z0-9\s_]{1,30}/,
      { message: CONFIG_NAME_ERROR }
    ),
  targetTorque: z
    .string({
      required_error: 'Target Torque is required',
      invalid_type_error: 'Incorrect',
    })
    .regex(
      /[A-Za-z0-9\s_.,]+/,
      { message: TARGET_TORQUE_ERROR }
    ),
});

export type NonMLFlowAnalysisSchema = z.infer<ReturnType<typeof nonMLValidationSchema>>;
