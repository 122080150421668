import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { CLIPBOARD_TEXT, TOOLTIP_TEXT } from "./analysis.constants";
import { useEffect } from "react";
import { useStepperContext } from "../../providers/StepperProvider";
import { Navigate } from "react-router-dom";
import { UploadDropContainer } from "../../components/uploadDropContainer/uploadDropContainer";
import { TabProps } from "./types";
import { createModelConfig } from "../upload/uploadHelpers";
import { useCSVContext } from "../../providers/CSVProvider";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { PrepareAnalysisSchema, prepareAnalysisSchema } from "./validations";
import {
  errorColors,
  primaryColors,
  tertiaryColors,
} from "../../theme/DarkTheme";
import { CopyToClipboard } from "../../components/CopyToClipboard/CopyToClipboard";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useAnalysisContext } from "../../providers/AnalysisProvider";

const PageContainerBoxStyle = {
  width: "100%",
  display: "flex",
};

const LeftTabBoxStyle = {
  display: "flex",
  flexDirection: "column",
  flexBasis: "50%",
  paddingRight: "25px",
};

const RightTabBoxStyle = {
  display: "flex",
  flexDirection: "column",
  flexBasis: "50%",
};

const FormatTypographyStyle = {
  display: "flex",
  paddingLeft: "32px",
  marginTop: "1px",
  gap: 0.6,
};

const ValidationButtonStyle = {
  position: "absolute",
  bottom: "32px",
  right: "32px",
  width: "240px",
};

export const PrepareAnalysis: React.FC<TabProps> = ({
  uploadContainerProps,
  ...props
}) => {
  const { setStep, setShowStepper } = useStepperContext();
  const {
    prepare: { toolId, processId, configName, targetTorque, targetAngle },
    setPrepareObj,
  } = useAnalysisContext();
  const { progress, setProgress, fileName } = useCSVContext();
  const { watch, formState, register, handleSubmit } = useForm({
    mode: "onChange",
    resolver: zodResolver(prepareAnalysisSchema(props.modelConfigs)),
    defaultValues: {
      toolId,
      processId,
      configName,
      targetTorque,
      targetAngle,
      fileName,
    },
  });

  const values = watch();

  useEffect(() => {
    setPrepareObj({
      toolId: values.toolId ?? "",
      processId: values.processId ?? "",
      targetAngle: values.targetAngle ?? "",
      targetTorque: values.targetTorque ?? "",
      configName: values.configName ?? "",
    });
  }, [values]);

  useEffect(() => {
    setStep(1);
    setShowStepper(false);
  }, []);

  const disableButton =
    !values.configName ||
    !values.processId ||
    !values.targetTorque ||
    !values.toolId ||
    !props.file;

  const onSubmit = (data: PrepareAnalysisSchema) => {
    if (!disableButton) {
      setPrepareObj({
        toolId: data?.toolId ?? "",
        processId: data?.processId ?? "",
        targetAngle: data?.targetAngle ?? "",
        targetTorque: data?.targetTorque ?? "",
        configName: data?.configName ?? "",
      });

      createModelConfig({
        tool_id: data?.toolId,
        model_name: data?.configName,
        process_id: data?.processId,
        target_torque: data?.targetTorque,
        target_angle: data?.targetAngle,
        file_name: fileName,
      });
      props.handleUpload();
    }
  };
  if (progress === 100) {
    setProgress(0);

    return (
      <Navigate
        to={`/data-check?fileName=${fileName}&configName=${configName}`}
      />
    );
  }

  return (
    <>
      <Box sx={PageContainerBoxStyle}>
        <Box sx={LeftTabBoxStyle}>
          <Box sx={{ height: "100%" }}>
            <Typography>1. Define your analysis job.</Typography>

            <Box>
              <FormControl fullWidth>
                <InputLabel
                  sx={{
                    color: formState.errors.toolId
                      ? errorColors.main
                      : tertiaryColors.main,
                    "&.Mui-focused": {
                      color: formState.errors.toolId
                        ? errorColors.main
                        : primaryColors.main,
                    },
                  }}
                  htmlFor="ToolID"
                >
                  Tool ID
                </InputLabel>

                <Input
                  {...register("toolId")}
                  defaultValue={toolId}
                  inputProps={{ "data-testid": "toolId" }}
                  error={Boolean(formState.errors.toolId)}
                />

                {Boolean(formState.errors.toolId) && (
                  <Box className="h-[30px]" sx={{ textOverflow: "ellipsis" }}>
                    <Typography
                      color={"error.main"}
                      className="text-xs"
                      data-testid="toolId-error"
                    >
                      {formState.errors.toolId?.message}
                    </Typography>
                  </Box>
                )}
              </FormControl>

              <FormControl fullWidth>
                <InputLabel
                  sx={{
                    color: formState.errors.processId
                      ? errorColors.main
                      : tertiaryColors.main,
                    "&.Mui-focused": {
                      color: formState.errors.processId
                        ? errorColors.main
                        : primaryColors.main,
                    },
                  }}
                  htmlFor="ProcessID"
                >
                  Process ID
                </InputLabel>

                <Input
                  {...register("processId")}
                  defaultValue={processId}
                  error={Boolean(formState.errors.processId)}
                  inputProps={{ "data-testid": "processId" }}
                />

                {Boolean(formState.errors.processId) && (
                  <Box className="h-[30px]">
                    <Typography
                      className="text-red-600 text-xs"
                      data-testid="processId-error"
                    >
                      {formState.errors.processId?.message}
                    </Typography>
                  </Box>
                )}
              </FormControl>

              <FormControl fullWidth>
                <InputLabel
                  sx={{
                    color: formState.errors.configName
                      ? errorColors.main
                      : tertiaryColors.main,
                    "&.Mui-focused": {
                      color: formState.errors.configName
                        ? errorColors.main
                        : primaryColors.main,
                    },
                  }}
                  htmlFor="ConfigName"
                >
                  Name your analysis job
                </InputLabel>

                <Input
                  {...register("configName")}
                  defaultValue={configName}
                  error={Boolean(formState.errors.configName)}
                  inputProps={{ "data-testid": "configName" }}
                />

                {Boolean(formState.errors.configName) && (
                  <Box className="h-[30px]">
                    <Typography
                      className="text-red-600 text-xs"
                      data-testid="configName-error"
                    >
                      {formState.errors.configName?.message}
                    </Typography>
                  </Box>
                )}
              </FormControl>

              <FormControl fullWidth>
                <InputLabel
                  htmlFor="targetTorque"
                  sx={{
                    color: formState.errors.targetTorque
                      ? errorColors.main
                      : tertiaryColors.main,
                    "&.Mui-focused": {
                      color: formState.errors.targetTorque
                        ? errorColors.main
                        : primaryColors.main,
                    },
                  }}
                >
                  Target Torque
                </InputLabel>

                <Input
                  {...register("targetTorque")}
                  defaultValue={targetTorque}
                  error={Boolean(formState.errors.targetTorque)}
                  inputProps={{ "data-testid": "targetTorque" }}
                />

                {Boolean(formState.errors.targetTorque) && (
                  <Typography
                    className="text-red-600 text-xs"
                    data-testid="targetTorque-error"
                  >
                    {formState.errors.targetTorque?.message}
                  </Typography>
                )}
              </FormControl>

              <FormControl fullWidth>
                <InputLabel htmlFor="targetTorque">Target Angle</InputLabel>

                <Input
                  {...register("targetAngle")}
                  defaultValue={targetAngle}
                  disabled={
                    !configName || !processId || !targetTorque || !toolId
                  }
                  inputProps={{ "data-testid": "targetAngle" }}
                />

                <Typography className="pt-2 pl-4">Optional</Typography>
              </FormControl>
            </Box>
          </Box>
        </Box>

        <Box sx={RightTabBoxStyle}>
          <Typography sx={FormatTypographyStyle}>
            2. Upload a training file. Use this
            <CopyToClipboard
              className="inline"
              text={CLIPBOARD_TEXT}
              label={"format "}
            />
            <Tooltip
              sx={{ marginTop: "1px" }}
              title={<Box sx={{ fontSize: "12px" }}>{TOOLTIP_TEXT}</Box>}
            >
              <InfoOutlinedIcon
                sx={{ marginTop: "1px", color: primaryColors.main }}
                fontSize={"small"}
              />
            </Tooltip>
            .
          </Typography>

          <UploadDropContainer {...uploadContainerProps} />
        </Box>
      </Box>

      <Button
        variant="contained"
        data-testid="submit-button"
        sx={ValidationButtonStyle}
        onClick={handleSubmit(onSubmit)}
        disabled={disableButton}
      >
        Validate
      </Button>
    </>
  );
};
